.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.logo-simple {
    display: none;
}

.logo-simple,
.logo-full {
    height: 35px;
}

.main-header {
    width: 100%;
    border-bottom: 2px solid var(--inactive-slider, #DCDDDF);
    background-color: var(--white, #FFFFFF);
}

.logo-container>div,
.logo-container>img {
    width: 33%;
}

.logo-container {
    height: 71px;
    width: 1140px;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 0 auto;
}

.headline {
    height: 140px;
    background: var(--clp-header, #EBEDEF);
    color: var(--base-color, #303030);
    width: 100%;
    font-family: "Roboto-Bold", Arial, sans-serif;
    line-height: normal;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

.headline p {
    margin: 0 auto;
    margin-top: 30px;
}


@media (min-width: 585px) {
    .headline p {
        width: 530px;
    }

    .headline {
        height: 88px;
    }

    .logo-container {
        width: 530px;
    }

    .logo-simple {
        display: block;
    }

    .logo-full {
        display: none;
    }
}

@media (min-width: 1140px) {
    .headline p {
        width: 1140px;
    }

    .headline {
        height: 140px;
    }

    .logo-container {
        width: 1140px;
    }

    .logo-simple {
        display: none;
    }

    .logo-full {
        display: block;
    }
}

@media (max-width: 584px) {
    .headline p {
        width: auto;
        margin-left: 27px;
    }

    .headline {
        height: 88px;
    }

    .logo-container {
        width: auto;
        margin-left: 27px;
        margin-right: 27px;
    }

    .logo-simple {
        display: block;
    }

    .logo-full {
        display: none;
    }
}
