.link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-wrap: nowrap;
}

.link-container a {
    margin: 0 15px;
}

.footer {
    background-color: var(--white, #FFFFFF);
    width: 100%;
    min-height: 100px;
    display: flex;
    align-items: center;
}

.header+.footer{
    margin-top: auto;
}

@media (min-width: 584px) {}

@media (min-width: 1140px) {}

@media (max-width: 583px) {
    .footer {
        min-height: 128px;
    }

    .link-container {
        flex-wrap: wrap;
    }
}
