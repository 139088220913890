:root {
    --cube-big-size: 40px;
    --cube-middle-size: 30px;
    --cube-small-size: 24px;
    --cube-middle-position: 10px;
    --cube-small-position: 16px;
    --cube-position-transformed: 40px;
    --spinner-duration: 4s;
}

.a-cubespinner--small {
    display: block;
    position: relative;
    height: 80px;
    width: 80px;
}

.a-cubespinner--small .a-cubespinner-big {
    animation: small-big var(--spinner-duration) infinite linear;
    height: var(--cube-big-size);
    width: var(--cube-big-size);
}

.a-cubespinner--small .a-cubespinner-middle {
    animation: small-middle var(--spinner-duration) infinite linear;
    height: var(--cube-middle-size);
    left: var(--cube-middle-position);
    top: var(--cube-middle-position);
    width: var(--cube-middle-size);
}

.a-cubespinner--small .a-cubespinner-small {
    animation: small-small var(--spinner-duration) infinite linear;
    height: var(--cube-small-size);
    left: var(--cube-small-position);
    top: var(--cube-small-position);
    width: var(--cube-small-size);
}

@keyframes small-big {
    0% {
        left: 0;
        top: 0;
    }

    9% {
        left: var(--cube-big-size);
        top: 0;
    }

    17% {
        left: var(--cube-big-size);
        top: 0;
    }

    25% {
        left: var(--cube-big-size);
        top: 0;
    }

    34% {
        left: var(--cube-big-size);
        top: var(--cube-big-size);
    }

    42% {
        left: var(--cube-big-size);
        top: var(--cube-big-size);
    }

    50% {
        left: var(--cube-big-size);
        top: var(--cube-big-size);
    }

    59% {
        left: 0;
        top: var(--cube-big-size);
    }

    67% {
        left: 0;
        top: var(--cube-big-size);
    }

    75% {
        left: 0;
        top: var(--cube-big-size);
    }

    84% {
        left: 0;
        top: 0;
    }

    92% {
        left: 0;
        top: 0;
    }

    100% {
        left: 0;
        top: 0;
    }
}

@keyframes small-middle {
    0% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    9% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    17% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    25% {
        left: var(--cube-middle-position);
        top: var(--cube-position-transformed);
    }

    34% {
        left: var(--cube-middle-position);
        top: var(--cube-position-transformed);
    }

    42% {
        left: var(--cube-middle-position);
        top: var(--cube-position-transformed);
    }

    50% {
        left: var(--cube-middle-position);
        top: var(--cube-middle-position);
    }

    59% {
        left: var(--cube-middle-position);
        top: var(--cube-middle-position);
    }

    67% {
        left: var(--cube-middle-position);
        top: var(--cube-middle-position);
    }

    75% {
        left: var(--cube-position-transformed);
        top: var(--cube-middle-position);
    }

    84% {
        left: var(--cube-position-transformed);
        top: var(--cube-middle-position);
    }

    92% {
        left: var(--cube-position-transformed);
        top: var(--cube-middle-position);
    }

    100% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }
}

@keyframes small-small {
    0% {
        left: var(--cube-small-position);
        top: var(--cube-position-transformed);
    }

    9% {
        left: var(--cube-small-position);
        top: var(--cube-position-transformed);
    }

    17% {
        left: var(--cube-small-position);
        top: var(--cube-small-position);
    }

    25% {
        left: var(--cube-small-position);
        top: var(--cube-small-position);
    }

    34% {
        left: var(--cube-small-position);
        top: var(--cube-small-position);
    }

    42% {
        left: var(--cube-position-transformed);
        top: var(--cube-small-position);
    }

    50% {
        left: var(--cube-position-transformed);
        top: var(--cube-small-position);
    }

    59% {
        left: var(--cube-position-transformed);
        top: var(--cube-small-position);
    }

    67% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    75% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    84% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    92% {
        left: var(--cube-small-position);
        top: var(--cube-position-transformed);
    }

    100% {
        left: var(--cube-small-position);
        top: var(--cube-position-transformed);
    }
}
