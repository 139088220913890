.header.language {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.header.language:hover,
.header.language select:hover,
.header.language select:active,
.header.language select:focus,
.header.language .clp-custom-select:hover {
    color: var(--button-hover-grey);
    -moz-outline-style: none;
    -webkit-focus-ring-color: transparent;
    outline: none;
}

.header.language .language-container::before {
    content: "\e918";
    font-family: 'MF-Iconset-bold';
    font-size: 24px;
    line-height: 71px;
    margin-right: 10px;
}

.header.language select {
    min-height: 0;
}

.header.language-selector {
    box-shadow: none;
    border: none;
    color: var(--base-color, #303030);
    background: var(--white, #FFFFFF);
    font-size: 16.5px;
    font-family: "Roboto-Light", Arial, sans-serif;
    font-style: normal;
    font-weight: 300;
    line-height: 71px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 71px;
}

.header .language-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 180px;
}

.header .clp-custom-select {
    height: 71px;
    border: none;
    background: var(--white, #FFFFFF);
    color: var(--base-color, #303030);
    font-family: "Roboto-Light", Arial, sans-serif;
    font-weight: 300;
    font-size: 16.5px;
    line-height: 71px;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
}

.header .clp-custom-dropdown {
    position: absolute;
    z-index: 99;
    width: 180px;
    background: var(--white, #FFFFFF);
    color: var(--base-color, #303030);
    font-family: "Roboto-Light", Arial, sans-serif;
    max-height: calc(4*58px);
    overflow: auto;
    border: 1px solid var(--muted, #8D8F95);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    top: 71px;
}

.header .clp-custom-dropdown>div {
    cursor: pointer;
    border-top: 1px solid var(--inactive-slider, #DCDDDF);
    height: 57px;
    display: flex;
    justify-content: left;
    align-items: center;
    text-indent: 18px;
}

.header .clp-custom-dropdown div:hover {
    background: var(--inactive-slider, #DCDDDF);
}

.header .clp-same-as-selected {
    color: var(--muted, #8D8F95);
}

.header .clp-custom-select-placeholder {
    font-family: "Roboto-Light", Arial, sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 16.5px;
    line-height: 27px;
    color: var(--muted, #8D8F95);
    opacity: 1;
}

.header .clp-custom-dropdown-hidden {
    display: none;
}

@media (min-width: 585px) {
    .header.language .language-container select {
        display: none;
    }
}

@media (min-width: 1140px) {
    .header.language .language-container select {
        display: none;
    }
}

@media (max-width: 584px) {
    .header.language .language-container .clp-custom-select {
        display: none;
    }
}
