.content.error {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.error-description {
    margin: 0;
    white-space: pre-line;
}

.error-logo {
    width: 51px;
}

.error-title {
    font-family: "Roboto-Bold", Arial, sans-serif;
    font-weight: 700;
    font-size: 32px;
    margin: 42px 0;
}

.back-button {
    margin-top: 42px;
    display: flex;
    width: 263px;
    height: 53px;
    background: var(--muted, #8D8F95);
    text-decoration: none;
    font-weight: 400;
    font-size: 16.5px;
    font-family: "Roboto-Regular", Arial, sans-serif;
    line-height: 27px;
    -ms-flex-line-pack: center;
    align-content: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .4);
    color: var(--white, #FFFFFF);
}

.back-button:hover {
    background-color: var(--button-hover-grey);
    color: var(--white, #FFFFFF);
}