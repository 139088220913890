:root {
    --cube-medium-big-size: 50px;
    --cube-medium-middle-size: 30px;
    --cube-medium-small-size: 20px;
    --cube-medium-middle-position: 20px;
    --cube-medium-small-position: 30px;
    --cube-medium-position-transformed: 50px;
    --medium-spinner-duration: 4s;
}

.a-cubespinner--medium {
    display: block;
    position: relative;
    height: 100px;
    width: 100px;
}

.a-cubespinner--medium .a-cubespinner-big {
    animation: medium-big var(--medium-spinner-duration) infinite linear;
    height: var(--cube-medium-big-size);
    width: var(--cube-medium-big-size);
}

.a-cubespinner--medium .a-cubespinner-middle {
    animation: medium-middle var(--medium-spinner-duration) infinite linear;
    height: var(--cube-medium-middle-size);
    left: var(--cube-medium-middle-position);
    top: var(--cube-medium-middle-position);
    width: var(--cube-medium-middle-size);
}

.a-cubespinner--medium .a-cubespinner-small {
    animation: medium-small var(--medium-spinner-duration) infinite linear;
    height: var(--cube-medium-small-size);
    left: var(--cube-medium-small-position);
    top: var(--cube-medium-small-position);
    width: var(--cube-medium-small-size);
}

@keyframes medium-big {
    0% {
        left: 0;
        top: 0;
    }

    9% {
        left: var(--cube-medium-big-size);
        top: 0;
    }

    17% {
        left: var(--cube-medium-big-size);
        top: 0;
    }

    25% {
        left: var(--cube-medium-big-size);
        top: 0;
    }

    34% {
        left: var(--cube-medium-big-size);
        top: var(--cube-medium-big-size);
    }

    42% {
        left: var(--cube-medium-big-size);
        top: var(--cube-medium-big-size);
    }

    50% {
        left: var(--cube-medium-big-size);
        top: var(--cube-medium-big-size);
    }

    59% {
        left: 0;
        top: var(--cube-medium-big-size);
    }

    67% {
        left: 0;
        top: var(--cube-medium-big-size);
    }

    75% {
        left: 0;
        top: var(--cube-medium-big-size);
    }

    84% {
        left: 0;
        top: 0;
    }

    92% {
        left: 0;
        top: 0;
    }

    100% {
        left: 0;
        top: 0;
    }
}

@keyframes medium-middle {
    0% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-position-transformed);
    }

    9% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-position-transformed);
    }

    17% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-position-transformed);
    }

    25% {
        left: var(--cube-medium-middle-position);
        top: var(--cube-medium-position-transformed);
    }

    34% {
        left: var(--cube-medium-middle-position);
        top: var(--cube-medium-position-transformed);
    }

    42% {
        left: var(--cube-medium-middle-position);
        top: var(--cube-medium-position-transformed);
    }

    50% {
        left: var(--cube-medium-middle-position);
        top: var(--cube-medium-middle-position);
    }

    59% {
        left: var(--cube-medium-middle-position);
        top: var(--cube-medium-middle-position);
    }

    67% {
        left: var(--cube-medium-middle-position);
        top: var(--cube-medium-middle-position);
    }

    75% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-middle-position);
    }

    84% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-middle-position);
    }

    92% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-middle-position);
    }

    100% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-position-transformed);
    }
}

@keyframes medium-small {
    0% {
        left: var(--cube-medium-small-position);
        top: var(--cube-medium-position-transformed);
    }

    9% {
        left: var(--cube-medium-small-position);
        top: var(--cube-medium-position-transformed);
    }

    17% {
        left: var(--cube-medium-small-position);
        top: var(--cube-medium-small-position);
    }

    25% {
        left: var(--cube-medium-small-position);
        top: var(--cube-medium-small-position);
    }

    34% {
        left: var(--cube-medium-small-position);
        top: var(--cube-medium-small-position);
    }

    42% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-small-position);
    }

    50% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-small-position);
    }

    59% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-small-position);
    }

    67% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-position-transformed);
    }

    75% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-position-transformed);
    }

    84% {
        left: var(--cube-medium-position-transformed);
        top: var(--cube-medium-position-transformed);
    }

    92% {
        left: var(--cube-medium-small-position);
        top: var(--cube-medium-position-transformed);
    }

    100% {
        left: var(--cube-medium-small-position);
        top: var(--cube-medium-position-transformed);
    }
}
