:root {
    --cube-big-size: 74px;
    --cube-middle-size: 40px;
    --cube-small-size: 30px;
    --cube-middle-position: 34px;
    --cube-position-transformed: 74px;
    --cube-small-position: 44px;
    --spinner-duration: 4s;
}

.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.a-cubespinner {
    display: block;
    height: 148px;
    position: relative;
    width: 148px;
}

.a-cubespinner-big {
    animation: big var(--spinner-duration) infinite linear;
    width: var(--cube-big-size);
    height: var(--cube-big-size);
    background-color: var(--mf-color01);
    display: block;
    left: 0;
    position: absolute;
    top: 0;
}

.a-cubespinner-middle {
    animation: middle var(--spinner-duration) infinite linear;
    height: var(--cube-middle-size);
    width: var(--cube-middle-size);
    animation-iteration-count: infinite;
    background-color: var(--mf-color02);
    display: block;
    left: var(--cube-middle-position);
    position: absolute;
    top: var(--cube-middle-position);
}

.a-cubespinner-small {
    animation: small var(--spinner-duration) infinite linear;
    height: var(--cube-small-size);
    width: var(--cube-small-size);
    animation-iteration-count: infinite;
    background-color: var(--mf-color03);
    display: block;
    left: var(--cube-small-position);
    position: absolute;
    top: var(--cube-small-position);
}

@keyframes big {
    0% {
        left: 0;
        top: 0;
    }

    9% {
        left: var(--cube-big-size);
        top: 0;
    }

    17% {
        left: var(--cube-big-size);
        top: 0;
    }

    25% {
        left: var(--cube-big-size);
        top: 0;
    }

    34% {
        left: var(--cube-big-size);
        top: var(--cube-big-size);
    }

    42% {
        left: var(--cube-big-size);
        top: var(--cube-big-size);
    }

    50% {
        left: var(--cube-big-size);
        top: var(--cube-big-size);
    }

    59% {
        left: 0;
        top: var(--cube-big-size);
    }

    67% {
        left: 0;
        top: var(--cube-big-size);
    }

    75% {
        left: 0;
        top: var(--cube-big-size);
    }

    84% {
        left: 0;
        top: 0;
    }

    92% {
        left: 0;
        top: 0;
    }

    100% {
        left: 0;
        top: 0;
    }
}

@keyframes middle {
    0% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    9% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    17% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    25% {
        left: var(--cube-middle-position);
        top: var(--cube-position-transformed);
    }

    34% {
        left: var(--cube-middle-position);
        top: var(--cube-position-transformed);
    }

    42% {
        left: var(--cube-middle-position);
        top: var(--cube-position-transformed);
    }

    50% {
        left: var(--cube-middle-position);
        top: var(--cube-middle-position);
    }

    59% {
        left: var(--cube-middle-position);
        top: var(--cube-middle-position);
    }

    67% {
        left: var(--cube-middle-position);
        top: var(--cube-middle-position);
    }

    75% {
        left: var(--cube-position-transformed);
        top: var(--cube-middle-position);
    }

    84% {
        left: var(--cube-position-transformed);
        top: var(--cube-middle-position);
    }

    92% {
        left: var(--cube-position-transformed);
        top: var(--cube-middle-position);
    }

    100% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }
}

@keyframes small {
    0% {
        left: var(--cube-small-position);
        top: var(--cube-position-transformed);
    }

    9% {
        left: var(--cube-small-position);
        top: var(--cube-position-transformed);
    }

    17% {
        left: var(--cube-small-position);
        top: var(--cube-small-position);
    }

    25% {
        left: var(--cube-small-position);
        top: var(--cube-small-position);
    }

    34% {
        left: var(--cube-small-position);
        top: var(--cube-small-position);
    }

    42% {
        left: var(--cube-position-transformed);
        top: var(--cube-small-position);
    }

    50% {
        left: var(--cube-position-transformed);
        top: var(--cube-small-position);
    }

    59% {
        left: var(--cube-position-transformed);
        top: var(--cube-small-position);
    }

    67% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    75% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    84% {
        left: var(--cube-position-transformed);
        top: var(--cube-position-transformed);
    }

    92% {
        left: var(--cube-small-position);
        top: var(--cube-position-transformed);
    }

    100% {
        left: var(--cube-small-position);
        top: var(--cube-position-transformed);
    }
}
