.registration-conditions-body {
    display: flex;
    flex-direction: column;
    font-family: "Roboto-Light", Arial, sans-serif;
    white-space: pre-line;
    display: block;
    margin: 0 auto;
    width: 1140px;
}

.registration-conditions-body h1 {
    font-family: "Roboto-Bold", Arial, sans-serif;
    font-weight: 700;
    font-size: 42px;
    line-height: 49px;
    margin: 25px 0;
}

.registration-conditions-body h2 {
    font-family: "Roboto-Regular", Arial, sans-serif;
    font-size: 26px;
    line-height: 32px;
    font-weight: 400;
}

.registration-conditions-body p {
    font-size: 16.5px;
}

@media (min-width: 584px){
    .registration-conditions-body {
        width: 584px;
        padding: 0 27px;
    }
}

@media (min-width: 1140px){
    .registration-conditions-body {
        width: 1140px;
        padding: 0;
    }
}

@media (max-width: 583px){
    .registration-conditions-body {
        width: auto;
        padding: 0 27px;
    }

    .registration-conditions-body h1 {
        font-family: "Roboto-Regular", Arial, sans-serif;
        font-size: 26px;
        line-height: 32px;
        font-weight: 700;
    }
}