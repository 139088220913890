html {
    height: 100%;
}

body {
    background-color: var(--white, #FFFFFF);
    height: 100%;
}

#root {
    height: 100%;
}

.app {
    font-family: "Roboto-Light", Arial, sans-serif;
    line-height: 27px;
    font-size: 16.5px;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 390px;
}

a {
    color: var(--link-color, #0071A9);
    font-family: "Roboto-Regular", Arial, sans-serif;
    font-size: 16.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    text-decoration: none;
}

a:hover {
    color: #5ECAFF
}

@font-face {
    font-family: MF-Iconset-light;
    font-style: normal;
    font-weight: 400;
    src: url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-light-v01.eot);
    src: url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-light-v01.eot#iefix) format("embedded-opentype"), url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-light-v01.woff) format("woff"), url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-light-v01.ttf) format("truetype"), url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-light-v01.svg#mef-iconset-light-v01) format("svg");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: MF-Iconset-bold;
    font-style: normal;
    font-weight: 400;
    src: url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-bold-v01.eot);
    src: url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-bold-v01.eot#iefix) format("embedded-opentype"), url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-bold-v01.woff) format("woff"), url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-bold-v01.ttf) format("truetype"), url(https://assets.login.messefrankfurt.com/fonts/mef-iconset-bold-v01.svg#mef-iconset-bold-v01) format("svg");
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'Roboto-Light';
    font-style: normal;
    font-weight: 300;
    src: url("https://assets.login.messefrankfurt.com/fonts/roboto-light-webfont.eot");
    src: url("https://assets.login.messefrankfurt.com/fonts/roboto-light-webfont.eot#iefix") format("embedded-opentype"), url("https://assets.login.messefrankfurt.com/fonts/roboto-light-webfont.woff2") format("woff2"), url("https://assets.login.messefrankfurt.com/fonts/roboto-light-webfont.woff") format("woff"), url("https://assets.login.messefrankfurt.com/fonts/roboto-light-webfont.ttf") format("truetype"), url("https://assets.login.messefrankfurt.com/fonts/roboto-light-webfont.svg#roboto-light-webfont") format("svg");
    text-rendering: optimizeLegibility
}

@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 400;
    src: url("https://assets.login.messefrankfurt.com/fonts/roboto-regular-webfont.eot");
    src: url("https://assets.login.messefrankfurt.com/fonts/roboto-regular-webfont.eot#iefix") format("embedded-opentype"), url("https://assets.login.messefrankfurt.com/fonts/roboto-regular-webfont.woff2") format("woff2"), url("https://assets.login.messefrankfurt.com/fonts/roboto-regular-webfont.woff") format("woff"), url("https://assets.login.messefrankfurt.com/fonts/roboto-regular-webfont.ttf") format("truetype"), url("https://assets.login.messefrankfurt.com/fonts/roboto-regular-webfont.svg#roboto-regular-webfont") format("svg");
    text-rendering: optimizeLegibility
}

@font-face {
    font-family: 'Roboto-Bold';
    font-style: normal;
    font-weight: 700;
    src: url("https://assets.login.messefrankfurt.com/fonts/roboto-bold-webfont.eot");
    src: url("https://assets.login.messefrankfurt.com/fonts/roboto-bold-webfont.eot#iefix") format("embedded-opentype"), url("https://assets.login.messefrankfurt.com/fonts/roboto-bold-webfont.woff2") format("woff2"), url("https://assets.login.messefrankfurt.com/fonts/roboto-bold-webfont.woff") format("woff"), url("https://assets.login.messefrankfurt.com/fonts/roboto-bold-webfont.ttf") format("truetype"), url("https://assets.login.messefrankfurt.com/fonts/roboto-bold-webfont.svg#roboto-bold-webfont") format("svg");
    text-rendering: optimizeLegibility
}

:root {
    --font-light: "Roboto-Light", Arial, sans-serif;
    --font-regular: "Roboto-Regular", Arial, sans-serif;
    --font-bold: "Roboto-Bold", Arial, sans-serif;
    --card-background: #F3F3F3;
    --inactive-slider: #DCDDDF;
    --white: #FFFFFF;
    --brand-hover: #5B0411;
    --brand-hyperlink-dark: #C20924;
    --brand-hyperlink-default: #DF0A29;
    --flyout-background: #E90A29, 40%;
    --muted: #8D8F95;
    --base-color: #303030;
    --selected: #0071A9;
    --button-hover-grey: #6F7076;
    --link-color: #0071A9;
    --registration-background: #EBEDEF;
    --border-success: #55802F;
    --clp-header: #EBEDEF;
    --mf-color01: #484848;
    --mf-color02: #808080;
    --mf-color03: #C3C3C3;
}
